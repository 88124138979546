// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/icon-arrow.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".edit_wrap_2z84h{min-height:100vh;padding-bottom:.96rem;flex-direction:column}.edit_wrap_2z84h,.edit_wrap_2z84h .edit_setting_mGybH{box-sizing:border-box;display:flex;justify-content:space-between}.edit_wrap_2z84h .edit_setting_mGybH{width:8.93333rem;height:1.33333rem;padding:0 .53333rem;background:#212121;border-radius:.42667rem;margin:0 auto .26667rem;align-items:center;font-weight:500;color:#fff}.edit_wrap_2z84h .edit_setting_mGybH.edit_mt20_1TFvh{margin-top:.53333rem}.edit_wrap_2z84h .edit_setting_mGybH .edit_uploadBlock_3XqNk{width:5.33333rem;height:1.33333rem;text-align:right;display:flex;justify-content:flex-end;align-items:center}.edit_wrap_2z84h .edit_setting_mGybH .edit_uploadBlock_3XqNk .edit_img_1z71F{width:1.06667rem;height:1.06667rem;border-radius:50%;margin-right:.34667rem}.edit_wrap_2z84h .edit_setting_mGybH .edit_uploadBlock_3XqNk .edit_icon_2ADbp{width:.26667rem;height:.32rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain}.edit_wrap_2z84h .edit_setting_mGybH:after{display:none}.edit_wrap_2z84h .edit_setting_mGybH .van-cell__title,.edit_wrap_2z84h .edit_setting_mGybH .van-field__control{color:#fff}.edit_wrap_2z84h .edit_setting_mGybH .van-field__control:disabled{font-weight:400;color:grey;-webkit-text-fill-color:grey}.edit_wrap_2z84h .edit_save_1yFn6{margin:2.66667rem auto 0;width:8.93333rem;height:1.17333rem;line-height:1.17333rem;text-align:center;background:#212121;border-radius:.74667rem}", ""]);
// Exports
exports.locals = {
	"wrap": "edit_wrap_2z84h",
	"setting": "edit_setting_mGybH",
	"mt20": "edit_mt20_1TFvh",
	"uploadBlock": "edit_uploadBlock_3XqNk",
	"img": "edit_img_1z71F",
	"icon": "edit_icon_2ADbp",
	"save": "edit_save_1yFn6"
};
module.exports = exports;
