<template>
  <div :class="$style.wrap">
    <div>
      <Back title="修改个人资料" />
      <van-field
        name="uploader"
        label="头像"
        input-align="right"
        :class="[$style.setting, $style.mt20]"
      >
        <template #input>
          <van-uploader :max-count="1" :after-read="afterRead">
            <div :class="$style.uploadBlock">
              <img
                v-if="headimg && !uploading"
                :src="headimg"
                :class="$style.img"
              />
              <van-loading v-if="uploading" :class="$style.img"></van-loading>
              <i :class="$style.icon"></i>
            </div>
          </van-uploader>
        </template>
      </van-field>
      <van-field
        :class="$style.setting"
        v-model="nickname"
        label="昵称"
        placeholder="请输入昵称"
        input-align="right"
      />
      <van-field
        :class="$style.setting"
        readonly
        clickable
        name="picker"
        input-align="right"
        :value="sexMap[gender]"
        label="性别"
        placeholder="点击选择性别"
        @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          value-key="name"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-field
        :class="$style.setting"
        v-model="mobile"
        disabled
        label="手机号"
        input-align="right"
      />
      <van-field
        :class="$style.setting"
        v-model="linkType"
        disabled
        label="链类型"
        input-align="right"
      />
      <van-field
        :class="$style.setting"
        v-model="wallet_address"
        disabled
        label="链上地址"
        input-align="right"
      />
    </div>

    <div :class="$style.save" @click="handleSave">确认保存</div>
  </div>
</template>

<script>
import Back from "@/components/Back";
import { fetchUserInfo, updateUserInfo, uploadToOss } from "@/api";
export default {
  components: {
    Back,
  },
  data() {
    return {
      nickname: "",
      gender: 1,
      mobile: "",
      headimg: "",
      sexMap: { 1: "男", 2: "女" },
      showPicker: false,
      columns: [
        { name: "男", gender: 1 },
        { name: "女", gender: 2 },
      ],
      uploading: false,
      linkType: "",
      wallet_address: "",
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      fetchUserInfo().then((res) => {
        const {
          mobile,
          nickname,
          gender,
          headimg,
          wallet_address,
          chain_type,
        } = res;
        this.nickname = nickname;
        this.mobile = mobile;
        this.gender = gender;
        this.headimg = headimg;
        this.wallet_address = wallet_address;
        this.linkType = chain_type;
      });
    },
    onConfirm(value) {
      this.gender = value.gender;
      this.showPicker = false;
    },
    afterRead(file) {
      this.uploading = true;
      let formData = new FormData();
      formData.append("file", file.file);
      uploadToOss(formData).then((res) => {
        this.headimg = res.image_url;
        this.uploading = false;
      });
    },
    handleSave() {
      if (!this.nickname.trim()) {
        this.$toast.fail("请输入昵称");
        return;
      }
      if (!Number(this.gender)) {
        this.$toast.fail("请选择性别");
        return;
      }
      const data = {
        nickname: this.nickname,
        gender: this.gender,
        headimg: this.headimg,
      };
      updateUserInfo(data).then(() => {
        this.$toast.success("用户信息更新成功！");
        this.$router.push({ path: "/personal" });
      });
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .setting {
    width: 335px;
    height: 50px;
    padding: 0 20px;
    box-sizing: border-box;
    background: #212121;
    border-radius: 16px;
    margin: 0 auto 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    color: #fff;
    &.mt20 {
      margin-top: 20px;
    }
    .uploadBlock {
      width: 200px;
      height: 50px;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 13px;
      }
      .icon {
        width: 10px;
        height: 12px;
        background: url("~@/assets/img/icon-arrow.png");
        background-size: contain;
      }
    }

    &::after {
      display: none;
    }
    :global {
      .van-cell__title {
        color: #fff;
      }
      .van-field__control {
        color: #fff;
        &:disabled {
          font-weight: 400;
          color: #808080;
          -webkit-text-fill-color: #808080;
        }
      }
    }
  }
  .save {
    margin: 100px auto 0;
    width: 335px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: #212121;
    border-radius: 28px;
  }
}
</style>
